/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~viewerjs/dist/viewer.css';

html, body {
	margin: 0;
	min-height: 100%;
	height: 100%;
}

body {
	font-family: "futura-pt", sans-serif;
}

button + button {
	margin-left: 5px !important;
}
